<template>
  <div>
    <h1 class="page-title">
      {{ type === "franchise" ? "Franchise" : "Scheme" }} Manager
    </h1>

    <SimpleTable
      :headers="headers"
      :items="items"
      :loading="loading"
      :searchLoading="searchLoading"
      :expanded_headers="expanded_headers"
      :totalCount="totalCount"
      :showEmailIcon="isUserAdmin"
      url_fragment="franchises"
      @hitPagination="fetchNextData"
      @hitSearch="fetchSearchedData"
      @add_item_click="add_item_click"
      @sendEmail="showEmailWarning"
      @edit_item_click="edit_item_click"
    />

    <v-dialog v-model="showMessageModal" max-width="750px">
      <v-card>
        <v-card-title>Confirm Action</v-card-title>
        <v-divider></v-divider>
        <div class="space-20"></div>
        <v-card-text>
          {{ emailMessage }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="closeMessageModal"
            v-if="emailSent"
            >Close</v-btn
          >
          <div v-else>
            <v-btn color="success" text @click="sendEmail()">
              <v-icon small left>mdi-check</v-icon>
              Send Email</v-btn
            >
            <v-btn color="error" text @click="showMessageModal = false"
              >Cancel</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SimpleTable from "@/components/common/SimpleTable";
import { crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";
import FranchiseService from "@/services/franchise.service.js";
import { bus } from "@/main.js";

export default {
  name: "FranchiseList",
  data() {
    return {
      headers: [
        {
          text: "Business Name",
          value: "name",
        },
        {
          text: "Business Activity",
          value: "business_activity",
        },
        {
          text: "Key person",
          value: "key_contact.person_name",
        },
        {
          text: "Insurance person",
          value: "insurance_contact.person_name",
        },
        {
          text: "Dedicated Broker ",
          value: "broker_contact.person_name",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Actions",
          value: "actions",
        },
        { text: "", value: "data-table-expand" },
      ],
      expanded_headers: [
        { text: "Communication", value: "comm_status" },
        { text: "Reminder Days", value: "comm_reminder" },
        { text: "Formal Policy No", value: "policy_ref_number" },
        { text: "Block All Policies (date)", value: "block_policy_date" },
        { text: "Block Renewal Process", value: "block_renewal_date" },
        { text: "Portal Login", value: "portal_link" },
      ],
      totalCount: 0,
      loading: false,
      crud_state: crud_state.LIST,
      items: [],
      item_to_edit: null,
      limit: 10,
      offset: 0,
      search: "",
      paginations: {},
      showMessageModal: false,
      emailEntityObj: {},
      emailMessage: "",
      emailSent: false,
      searchLoading: false,
    };
  },
  props: {
    type: String,
    type_id: Number,
  },
  components: {
    SimpleTable,
  },
  computed: {
    ...mapGetters("auth", ["userRole"]),

    isUserAdmin() {
      return this.userRole.includes("ADMIN");
    },
  },
  watch: {
    type() {
      this.get_items({ type_id: 3 });
    },
  },
  async mounted() {
    this.items = await this.get_items({ type_id: this.type_id });
    bus.$on("edit_save", async () => {
      this.$store.dispatch("common/setFlashMsg", "Saved successfully");
      // refresh data
      this.items = await this.get_items({ type_id: this.type_id });
    });
  },
  methods: {
    async fetchNextData(pagination) {
      this.items = await this.get_items({ type_id: this.type_id, pagination });
    },
    async fetchSearchedData(search) {
      if (search) {
        const limit = this.paginations.itemsPerPage;
        const offset =
          (this.paginations.page - 1) * this.paginations.itemsPerPage + 1;

        this.searchLoading = true;
        let filters = { limit: limit, start: offset, search };

        try {
          let res = await FranchiseService.getFranchisesSuggestions(filters);
          this.totalCount = res.data.count;
          this.items = res.data.results;
        } catch (e) {
          console.error(e);
        } finally {
          this.searchLoading = false;
        }
      } else {
        this.items = await this.get_items(this.paginations);
      }
    },
    async get_items({ type_id, pagination = { itemsPerPage: 10, page: 1 } }) {
      this.paginations = { ...pagination };
      const limit = pagination.itemsPerPage;
      const offset = (pagination.page - 1) * pagination.itemsPerPage + 1;

      this.loading = true;
      let service = new BaseService("franchises");
      let filters = { type_id: type_id, limit: limit, start: offset };

      try {
        let res = await service.get_all(filters);
        this.totalCount = res.data.count;
        return res.data.results;
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    closeMessageModal() {
      this.showMessageModal = false;
      this.emailSent = false;
    },
    showEmailWarning(franchise) {
      this.emailEntityObj = franchise;
      this.emailMessage = `Are you sure you want to email all the Franchisees of ${this.emailEntityObj.name}?`;
      this.showMessageModal = true;
    },
    async sendEmail() {
      let service = new BaseService("franchisees/invitations");
      let filters = {
        entity_id: this.emailEntityObj.id,
        invitation_type: "franchise",
      };

      try {
        let res = await service.create(filters);

        this.emailSent = true;
        if (res.data?.success) {
          this.emailMessage = `Mail sent successfully!`;
        } else {
          this.emailMessage = `Error Sending mail. Please try again later!`;
        }
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    add_item_click() {
      this.crud_state = crud_state.NEW;
      this.$router.push({
        path: `/franchise/add/${this.type}`,
        query: {
          crud_state: this.crud_state,
        },
      });
    },
    edit_item_click(id) {
      if (id) {
        this.crud_state = crud_state.EDIT;
        this.$router.push({
          path: `/franchise/edit/${this.type}/${id}`,
          query: {
            crud_state: this.crud_state,
          },
        });
      } else {
        this.edit_error("Invalid data passed");
      }
    },
  },
};
</script>
<style>
.custom-container {
  margin: 50px 50px;
}

.no-link {
  text-decoration: none;
}
</style>
